// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktionen-js": () => import("/opt/build/repo/src/pages/aktionen.js" /* webpackChunkName: "component---src-pages-aktionen-js" */),
  "component---src-pages-checklisten-js": () => import("/opt/build/repo/src/pages/checklisten.js" /* webpackChunkName: "component---src-pages-checklisten-js" */),
  "component---src-pages-erinnerungsfunktion-js": () => import("/opt/build/repo/src/pages/erinnerungsfunktion.js" /* webpackChunkName: "component---src-pages-erinnerungsfunktion-js" */),
  "component---src-pages-erstausstattung-js": () => import("/opt/build/repo/src/pages/erstausstattung.js" /* webpackChunkName: "component---src-pages-erstausstattung-js" */),
  "component---src-pages-impressum-js": () => import("/opt/build/repo/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personalisierbar-js": () => import("/opt/build/repo/src/pages/personalisierbar.js" /* webpackChunkName: "component---src-pages-personalisierbar-js" */),
  "component---src-pages-ratgeber-js": () => import("/opt/build/repo/src/pages/ratgeber.js" /* webpackChunkName: "component---src-pages-ratgeber-js" */),
  "component---src-pages-thankyou-js": () => import("/opt/build/repo/src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

